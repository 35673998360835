export class Cultures {
  public static all() {
    return [
      {
        "name": 'Afrikaans',
        "culture": 'af'
      },
      {
        "name": 'Arabic',
        "culture": 'ar'
      },
      {
        "name": 'Belarusian',
        "culture": 'be'
      },
      {
        "name": 'Bosnian',
        "culture": 'bs'
      },
      {
        "name": 'Bulgarian',
        "culture": 'bg'
      },
      {
        "name": 'Chinese',
        "culture": 'zh'
      },
      {
        "name": 'Croatian',
        "culture": 'hr'
      },
      {
        "name": 'Czech',
        "culture": 'cs'
      },
      {
        "name": 'Danish',
        "culture": 'da'
      },
      {
        "name": 'Dutch',
        "culture": 'nl'
      },
      {
        "name": 'English (GB)',
        "culture": 'en'
      },
      {
        "name": 'English (US)',
        "culture": 'en-us'
      },
      {
        "name": 'Finnish',
        "culture": 'fi'
      },
      {
        "name": 'French',
        "culture": 'fr'
      },
      {
        "name": 'French (Canadian)',
        "culture": 'fr-ca'
      },
      {
        "name": 'Georgian',
        "culture": 'ka'
      },
      {
        "name": 'German',
        "culture": 'de'
      },
      {
        "name": 'Greek',
        "culture": 'el'
      },
      {
        "name": 'Gujarati',
        "culture": 'gu-in'
      },
      {
        "name": 'Haitian (Creole)',
        "culture": 'ht'
      },
      {
        "name": 'Hebrew',
        "culture": 'he'
      },
      {
        "name": 'Hindi',
        "culture": 'hi'
      },
      {
        "name": 'Hungarian',
        "culture": 'hu'
      },
      {
        "name": 'Italian',
        "culture": 'it'
      },
      {
        "name": 'Japanese',
        "culture": 'ja'
      },
      {
        "name": 'Kannada',
        "culture": 'kn'
      },
      {
        "name": 'Korean',
        "culture": 'ko'
      },
      {
        "name": 'Latvian',
        "culture": 'lv'
      },
      {
        "name": 'Malay',
        "culture": 'ms'
      },
      {
        "name": 'Malayalam',
        "culture": 'ml'
      },
      {
        "name": 'Marathi',
        "culture": 'mr'
      },
      {
        "name": 'Norwegian',
        "culture": 'no'
      },
      {
        "name": 'Norwegian (Bokmål)',
        "culture": 'nb'
      },
      {
        "name": 'Polish',
        "culture": 'pl'
      },
      {
        "name": 'Portuguese',
        "culture": 'pt'
      },
      {
        "name": 'Portuguese (Brazilian)',
        "culture": 'pt-br'
      },
      {
        "name": 'Punjabi',
        "culture": 'pa'
      },
      {
        "name": 'Romanian',
        "culture": 'ro'
      },
      {
        "name": 'Russian',
        "culture": 'ru'
      },
      {
        "name": 'Serbian',
        "culture": 'sr'
      },
      {
        "name": 'Slovak',
        "culture": 'sk'
      },
      {
        "name": 'Southern Sotho',
        "culture": 'st'
      },
      {
        "name": 'Spanish',
        "culture": 'es'
      },
      {
        "name": 'Spanish (Latin America)',
        "culture": 'es-419'
      },
      {
        "name": 'Spanish (Puerto Rico)',
        "culture": 'es-pr'
      },
      {
        "name": 'Swedish',
        "culture": 'sv'
      },
      {
        "name": 'Tamil',
        "culture": 'ta'
      },
      {
        "name": 'Telugu',
        "culture": 'te'
      },
      {
        "name": 'Tswana',
        "culture": 'tn'
      },
      {
        "name": 'Thai',
        "culture": 'th'
      },
      {
        "name": 'Turkish',
        "culture": 'tr'
      },
      {
        "name": 'Ukrainian',
        "culture": 'uk'
      },
      {
        "name": 'Xhosa',
        "culture": 'xh'
      },
      {
        "name": 'Zulu',
        "culture": 'zu'
      }
    ];
  }
}
